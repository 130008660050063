import React, { useEffect } from 'react';
import Sidebar from './Common/Sidebar';
import Contentarea from './Common/Contentarea';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const C = (props) => {
  const selectedContent = useSelector((state) => state.data.selectedContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-md-7 contentdiv'>
            <Contentarea selectedContent={selectedContent} />
          </div>
          <div className='col-md-3'>
            <h5 style={{ marginTop: '6px' }}>ReactJs Setup Tutorial</h5>
            <iframe style={{ marginTop: '5px' }} width="100%" height="auto" src="https://www.youtube.com/embed/AtGvDjQu2gU?si=HEJTtWkx-oAYX21j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <br /><br />
            <div className="card test_card_body">
              <div className="card-body">
                <img
                  src='../../images/quiz_animi.gif'
                  alt="Quiz"
                  className="quiz-image"
                  style={{ width: '40%', height: 'auto', marginBottom: '15px' }}
                />
                <h5>Computer Teacher Exam Quiz</h5>
                <Link to="/ExerciseTest" className='quiz_card_button'>Start Quiz Now</Link>
                <p>Ready to start increase your knowledge with www.codingveda.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default C;
